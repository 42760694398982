import { useState } from 'react';
import { useActionData, useLoaderData, useSubmit } from 'react-router-dom';
import {
    SandpackProvider,
    SandpackLayout,
    SandpackCodeViewer,
} from '@codesandbox/sandpack-react';
import { python } from '@codemirror/lang-python';
import { Box, Code, Save, Trash2 } from 'react-feather';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import Question from './Questions';
import QuestionTypeSelect from './QuestionTypeSelect';
import {
    getNewQuestion,
    getQuestionFromData,
    getUpdatedQuestion,
} from './helpers';
import {
    Question as EDSLQuestion,
    QuestionType,
    QuestionWithOptionLabels,
    QuestionWithOptions,
} from './types';

interface ExistingQuestions {
    is_authenticated: boolean;
    is_beta_user: boolean;
    questions: EDSLQuestion[];
}

function Survey() {
    const starterQuestionData = useLoaderData() as ExistingQuestions;

    const isInEditMode = starterQuestionData.questions !== undefined;

    const surveyCode = useActionData() as string;

    const [questions, setQuestions] = useState<EDSLQuestion[]>(() => {
        if (isInEditMode) {
            const starterQuestions = starterQuestionData.questions.map(
                (question) => getQuestionFromData(question)
            );
            return starterQuestions;
        } else {
            const starterQuestions = [getNewQuestion('multiple_choice')];
            return starterQuestions;
        }
    });
    const submit = useSubmit();

    function addQuestion(type: QuestionType) {
        const newQuestion = getNewQuestion(type);
        setQuestions([...questions, newQuestion]);
    }

    function changeQuestionType(question: EDSLQuestion, newType: QuestionType) {
        const updatedQuestion = getUpdatedQuestion(question, newType);
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === question.id ? updatedQuestion : q
            )
        );
    }

    function updateQuestion(updatedQuestion: EDSLQuestion) {
        setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
                q.id === updatedQuestion.id ? updatedQuestion : q
            )
        );
    }

    function removeQuestion(questionId: string) {
        setQuestions((prevQuestions) =>
            prevQuestions.filter((q) => q.id !== questionId)
        );
    }

    function handleOptionLabelChange(
        question: QuestionWithOptionLabels,
        optionId: string,
        optionLabel: string
    ) {
        const newOptions = question.structure.options.map((option) =>
            option.id === optionId ? { ...option, label: optionLabel } : option
        );
        updateQuestion({
            ...question,
            structure: { ...question.structure, options: newOptions },
        } as QuestionWithOptionLabels);
    }

    function handleOptionTextChange(
        question: QuestionWithOptions,
        optionId: string,
        optionText: string
    ) {
        const newOptions = question.structure.options.map((option) =>
            option.id === optionId ? { ...option, text: optionText } : option
        );
        updateQuestion({
            ...question,
            structure: { ...question.structure, options: newOptions },
        } as QuestionWithOptions);
    }

    function addOption(question: QuestionWithOptions) {
        const newOption =
            question.type === 'linear_scale'
                ? {
                      id: crypto.randomUUID(),
                      text: `${question.structure.options.length + 1}`,
                      label: '',
                  }
                : {
                      id: crypto.randomUUID(),
                      text: `Option ${question.structure.options.length + 1}`,
                  };

        updateQuestion({
            ...question,
            structure: {
                ...question.structure,
                options: [...question.structure.options, newOption],
            },
        } as QuestionWithOptions);
    }

    function removeOption(question: QuestionWithOptions, optionId: string) {
        const newOptions = question.structure.options.filter(
            (option) => option.id !== optionId
        );
        updateQuestion({
            ...question,
            structure: {
                ...question.structure,
                options: newOptions,
            },
        } as QuestionWithOptions);
    }

    function handleCreateSurvey(intent: string) {
        const serializedQuestions = JSON.stringify(questions);
        submit(
            { questions: serializedQuestions, intent: intent },
            { method: 'post', encType: 'application/json' }
        );
    }

    return (
        <>
            {questions.map((question) => (
                <div
                    key={question.id}
                    className="flex flex-col mt-4 p-12 space-y-12 border dark:border-primary-dark-border rounded-md"
                >
                    <div className="flex flex-col md:flex-row gap-8">
                        <div className="relative flex grow">
                            <VisuallyHidden.Root>
                                <label
                                    className="block text-blue-500 dark:text-primary-dark-text-accent font-bold py-2"
                                    htmlFor={`${question.id}-text`}
                                >
                                    Question text
                                </label>
                            </VisuallyHidden.Root>
                            <input
                                type="text"
                                id={`${question.id}-text`}
                                name={`${question.id}-text`}
                                value={question.text}
                                placeholder="Question text"
                                onChange={(event) => {
                                    updateQuestion({
                                        ...question,
                                        text: event.target.value,
                                    });
                                }}
                                className="grow bg-slate-50 dark:bg-gray-700/30 focus:outline-none border-b-2 border-gray-400 focus:border-b-2 focus:border-b-blue-500 block p-2.5 dark:border-b-gray-500 dark:focus:border-b-blue-500 dark:placeholder-gray-400"
                            />
                        </div>
                        <QuestionTypeSelect
                            question={question}
                            changeQuestionType={changeQuestionType}
                        />
                    </div>
                    <Question
                        question={question}
                        updateQuestion={updateQuestion}
                        addOption={addOption}
                        handleOptionLabelChange={handleOptionLabelChange}
                        handleOptionTextChange={handleOptionTextChange}
                        removeOption={removeOption}
                    />
                    <div className="flex flex-col items-end">
                        <div
                            data-orientation="horizontal"
                            role="none"
                            className="shrink-0 bg-gray-300 dark:bg-gray-500 h-[1px] w-full my-4"
                        ></div>
                        <button
                            onClick={() => removeQuestion(question.id)}
                            className="grow-0 p-3 hover:bg-gray-300/20 transition-colors rounded-md text-gray-500"
                            aria-label="Remove question"
                        >
                            <Trash2 />
                        </button>
                    </div>
                </div>
            ))}
            <div className="flex justify-center">
                <button
                    onClick={() => addQuestion('free_text')}
                    className="flex px-4 py-2.5 hover:bg-gray-300/20 transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium"
                >
                    Add question
                </button>
            </div>
            <div>
                <div className="flex flex-row gap-4 mb-4">
                    <button
                        onClick={() => handleCreateSurvey('get_code')}
                        className="flex gap-2 px-6 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium"
                    >
                        <Code className="w-5" />
                        Get code
                    </button>
                    {isInEditMode ? (
                        <button
                            onClick={() => handleCreateSurvey('edit_object')}
                            className="flex gap-2 px-6 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium"
                        >
                            <Save className="w-5" />
                            Save changes to survey
                        </button>
                    ) : (
                        <button
                            onClick={() => handleCreateSurvey('create_object')}
                            className="flex gap-2 px-6 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium"
                        >
                            <Box className="w-5" />
                            Create survey object
                        </button>
                    )}
                </div>
                <SandpackProvider
                    files={{
                        'survey_code.py': {
                            code:
                                surveyCode !== undefined
                                    ? surveyCode
                                    : '# Nothing to see here yet!',
                            active: true,
                        },
                    }}
                    theme={'dark'}
                >
                    <SandpackLayout>
                        <SandpackCodeViewer
                            showLineNumbers={true}
                            showTabs={false}
                            additionalLanguages={[
                                {
                                    name: 'python',
                                    extensions: ['py'],
                                    language: python(),
                                },
                            ]}
                        ></SandpackCodeViewer>
                    </SandpackLayout>
                </SandpackProvider>
            </div>
        </>
    );
}

export default Survey;
