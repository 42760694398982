import { useState } from 'react';
import { Form, Link, useActionData, useNavigate } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import { AlertTriangle, Check } from 'react-feather';
import ContentContainer from '../base/ContentContainer';
import { GoogleIcon, MicrosoftIcon } from './Icons';
import { login } from '../../helpers/auth.helpers';

interface RegisterResponse {
    success: boolean;
    message: string;
}

function RegisterAlert({ response }: { response: RegisterResponse | null }) {
    if (!response) return null;

    const { success, message } = response;
    const alertClass = success
        ? 'bg-green-50 border-green-400 text-green-800'
        : 'bg-red-100 border-red-400 text-red-700';

    return (
        <div className={`border px-4 py-3 rounded ${alertClass}`}>
            {success ? (
                <Check className="inline w-4 h-4 mr-2" />
            ) : (
                <AlertTriangle className="inline w-4 h-4 mr-2" />
            )}
            {message}
        </div>
    );
}

function Login() {
    const [loginMessage, setLoginMessage] = useState<string>();
    const registerResponse = useActionData() as RegisterResponse;
    const navigate = useNavigate();

    function handleLogin(event: React.SyntheticEvent<HTMLFormElement>) {
        event.preventDefault();

        const form = event.currentTarget;

        const formData = new FormData(form);

        login(formData).then((responseMsg: string) => {
            if (responseMsg === 'Login successful!') {
                navigate('/home');
            } else {
                setLoginMessage(responseMsg);
            }
        });
    }

    const lightInputStyles =
        'w-full bg-white focus:outline-none mt-1 border border-gray-400 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block p-2.5';
    const darkInputStyles =
        'dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500';
    const inputStyles = `${lightInputStyles} ${darkInputStyles}`;

    const lightAuthBtnStyles =
        'self-stretch w-full flex items-center gap-3 bg-white text-left border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg px-5 py-2.5';
    const darkAuthBtnStyles =
        'dark:bg-gray-700/20 dark:text-primary-dark-text dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700';
    const authBtnStyles = `${lightAuthBtnStyles} ${darkAuthBtnStyles}`;

    return (
        <ContentContainer>
            <Tabs.Root
                className="w-full max-w-sm m-auto flex flex-col items-center items-stretch p-8 border-solid border-2 border-gray-300 dark:border-primary-dark-border rounded-lg text-sm shadow-md"
                defaultValue="tab1"
            >
                <Tabs.List
                    className="w-full flex mb-4 text-black"
                    aria-label="Manage your account"
                >
                    <Tabs.Trigger
                        className="flex-1 bg-gray-300 px-8 py-2 rounded-l-md data-[state=active]:bg-blue-500 data-[state=active]:text-white"
                        value="tab1"
                    >
                        Login
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="flex-1 bg-gray-300 px-8 py-2 rounded-r-md data-[state=active]:bg-green-500 data-[state=active]:text-white"
                        value="tab2"
                    >
                        Sign Up
                    </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content className="flex flex-col gap-4" value="tab1">
                    <Form
                        method="get"
                        action="/api/login/google"
                        reloadDocument
                    >
                        <button className={authBtnStyles}>
                            <GoogleIcon />
                            Continue with Google
                        </button>
                    </Form>
                    <Form
                        method="get"
                        action="/api/login/microsoft"
                        reloadDocument
                    >
                        <button className={authBtnStyles}>
                            <MicrosoftIcon />
                            Continue with Microsoft
                        </button>
                    </Form>
                    <Form
                        method="post"
                        onSubmit={handleLogin}
                        className="flex flex-col gap-4"
                    >
                        <fieldset>
                            <label htmlFor="login-email" className="block">
                                Email
                            </label>
                            <input
                                type="email"
                                id="login-email"
                                name="login_email"
                                required
                                className={inputStyles}
                            />
                        </fieldset>
                        <fieldset>
                            <label htmlFor="login-password" className="block">
                                Password
                            </label>
                            <input
                                type="password"
                                id="login-password"
                                name="login_password"
                                required
                                className={inputStyles}
                            ></input>
                        </fieldset>
                        <button className="self-stretch w-full bg-blue-500 text-white rounded-md p-2 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-200 active:bg-blue-700">
                            Continue
                        </button>
                    </Form>
                    {loginMessage && (
                        <div className="bg-red-100 border border-red-400 px-4 py-3 rounded text-red-700 text-center">
                            {loginMessage}
                        </div>
                    )}
                    <Link
                        to="/forgot-password"
                        className="block text-center font-semibold hover:text-gray-600 dark:hover:text-primary-dark-text-accent"
                    >
                        Forgot password?
                    </Link>
                </Tabs.Content>
                <Tabs.Content className="flex flex-col gap-4" value="tab2">
                    <Form
                        method="get"
                        action="/api/login/google"
                        reloadDocument
                    >
                        <button className={authBtnStyles}>
                            <GoogleIcon />
                            Continue with Google
                        </button>
                    </Form>
                    <Form
                        method="get"
                        action="/api/login/microsoft"
                        reloadDocument
                    >
                        <button className={authBtnStyles}>
                            <MicrosoftIcon />
                            Continue with Microsoft
                        </button>
                    </Form>
                    <Form method="post" className="flex flex-col gap-4">
                        <fieldset>
                            <label
                                htmlFor="register-username"
                                className="block"
                            >
                                Username
                                <br />
                                <span className="text-gray-700 dark:text-gray-400 text-xs">
                                    5-20 characters, letters and numbers only
                                </span>
                            </label>
                            <input
                                type="text"
                                id="register-username"
                                name="register_username"
                                required
                                className={inputStyles}
                            />
                        </fieldset>
                        <fieldset>
                            <label htmlFor="register-email" className="block">
                                Email
                            </label>
                            <input
                                type="email"
                                id="register-email"
                                name="register_email"
                                required
                                className={inputStyles}
                            />
                        </fieldset>
                        <fieldset>
                            <label
                                htmlFor="register-password"
                                className="block"
                            >
                                Password
                            </label>
                            <input
                                type="password"
                                id="register-password"
                                name="register_password"
                                required
                                className={inputStyles}
                            ></input>
                        </fieldset>
                        <button className="self-stretch w-full bg-green-500 text-white rounded-md p-2 hover:bg-green-600 focus:outline-none focus:border-green-700 focus:ring focus:ring-green-200 active:bg-green-700">
                            Continue
                        </button>
                    </Form>
                    <RegisterAlert response={registerResponse} />
                    <Link
                        to="/forgot-password"
                        className="block text-center font-semibold hover:text-gray-600 dark:hover:text-primary-dark-text-accent"
                    >
                        Forgot password?
                    </Link>
                </Tabs.Content>
            </Tabs.Root>
        </ContentContainer>
    );
}

export default Login;
